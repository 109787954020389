import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";

const PrivacyPolicy = () => {
  return (
    <>
      <Meta title={"Privacy Policy"} />
      <BreadCrumb title="Privacy Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <p>
              <h4>TheMaduraBags.in Privacy Notice</h4>

We know that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This Privacy Notice describes how TheMaduraBags and its affiliates including TheMaduraBags.in, Inc. (collectively "TheMaduraBags") collect and process your personal information through TheMaduraBags websites, devices, products, services, online marketplace and applications that reference this Privacy Notice (together "TheMaduraBags Services").
By using TheMaduraBags Services you agree to our use of your personal information (including sensitive personal information) in accordance with this Privacy Notice, as may be amended from time to time by us at our discretion. You also agree and consent to us collecting, storing, processing, transferring, and sharing your personal information (including sensitive personal information) with third parties or service providers for the purposes set out in this Privacy Notice.
Personal information subject to this Privacy Notice will be collected and retained by TheMaduraBags, with a registered office at #118, Nethaji Road, Madurai, Tamil Nadu-625001 India.

              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
