import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
//import { toast } from "react-toastify";
import { enquiryService } from "./enquiryService";
import { toast } from "react-toastify";

export const createQuery= createAsyncThunk(
        "enquiry/post",
        async (enquiryData,thunkAPI)=>{
    try {
        return await enquiryService.postQuery(enquiryData);
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
});

const enquiryState={
    enquiry:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:"",
}
export const enquirySlice=createSlice({
    name:"enquiry",
    initialState:enquiryState,
    reducers:{},
    extraReducers:(builders)=>{
        builders
        .addCase(createQuery.pending,(state)=>{
            state.isLoading=true;
        })
        .addCase(createQuery.fulfilled,(state,action)=>{
            state.isLoading=false;
            state.isError=false;
            state.isSuccess=true;
            state.enquiry=action.payload;
            if(state.isSuccess=== true)
            {
                toast.success("enquiry Form Submitted Successfully")
            }
        })
        .addCase(createQuery.rejected,(state,action)=>{
            state.isError=true;
            state.isLoading=false;
            state.isSuccess=false;
            state.message=action.error;
            if(state.isError=== true)
            {
                toast.success("Something went wrong")
            }
        });     
    },    
});

export default enquirySlice.reducer;