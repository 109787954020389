import React from "react";
import ReactDOM from "react-dom/client";
import { createRoot } from "react-dom/client";
//import "./index.css";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
root.render(<Provider store={store}><App /></Provider>);
