import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Container from "../components/Container";
import Meta from "../components/Meta";

const ShippingPolicy = () => {
  return (
    <>
      <Meta title={"Shippingg Pplicy"} />
      <BreadCrumb title="Shippingg Pplicy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
       <h3>     Shipping and Delivery</h3>
Know more about delivery options like guaranteed delivery, same-day & two-day delivery, delivery speeds & charges and secured delivery. 
Find information on delivery issues, delivery speeds and charges, rescheduling deliveries, special delivery options and more. 
Shipping Rates & Times
 
<ol>
Note:
<li>	If an order includes multiple items, each may have separate delivery dates and tracking information.</li>
<li>Items sent by sellers from the Amazon Marketplace can also be tracked, but only if the seller has shared this information with Amazon and you have chosen a traceable shipping method.</li>
<li>If your package was marked as delivered and you have not received the package, contact us within 7 days of the order being marked as delivered.</li>
<li>If your package shows as delivered, but you haven't received it, refer Find a Missing package that shows as Delivered Help page for more information.</li>
<li>If you haven't received your order but tracking shows as “Undelivered”, visit Undeliverable Packages Help page to know reasons why a package is undeliverable and the refund timelines for undelivered packages.</li>
<li>If the estimated delivery date for your parcel has passed and your tracking information hasn't changed, allow 1 additional day for parcels shipped by Amazon and 5 additional days for parcels shipped by third-party couriers to be delivered. Usually parcels arrive on or shortly after the estimated delivery date.</li>
</ol>
<h5>Delivery Options</h5>
<ul>
	<li>Same-day and Two-day Delivery</li>
<li>	Secured Delivery with One-time Password</li>
<li>Secured Delivery with Tamper-evident Packaging</li>
<li>Do Not Disturb Delivery</li>
<li>	Release Date Delivery</li>
<li>Free Shipping on Subsequent Orders</li>
</ul>
<h5>Guaranteed Delivery</h5>
<li>Guaranteed Delivery Options</li>
<li>Eligibility for Guaranteed Delivery</li>
<li>	Guaranteed Shipping Speeds and Delivery Charges</li>
<li>	Terms and Conditions for Guaranteed Delivery</li>

<h5>Shipping Speeds and Charges</h5>

We display shipping speeds and charges based on the items in your cart and the delivery address.
<ol>
Shipping Speed	Charge Per Item***: Prime Members	Charge Per Item***: Prime Lite Members	Charge Per Item***: Non-Prime Members
<li>  Same-Day Delivery	                    Free	                            ₹175	                                ₹175</li>
<li>One-Day Delivery	                      Free	                             ₹150	                                 ₹150</li>
<li>Two-Day Delivery	Free	Free	₹120</li>
<li>No-Rush Delivery	Free	Free	N.A</li>
<li>Standard Delivery**	Free	Free	₹40</li>
</ol>
<ul>
<li>**Standard Delivery charges are free for non-Prime members for orders ₹499 or more.</li>
<li>	**We deliver orders placed with Standard Delivery in two or more days after they are shipped. You can check the estimated delivery date before ordering.</li>
<li>	***The applicable delivery charge is levied even for multiple quantities of the same product. The shipping fee per item structure is limited to standard and fast track speeds for Prime and non-Prime customers mentioned in the table above.</li>
</ul>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ShippingPolicy;
