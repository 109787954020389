export const services=[
    {
    "title": "Free Shipping",
    "tagline": "From all orders over $5",
    "image":"images/service.png",
    },
    {
        "title": "Daily surprise Offers",
        "tagline": "Save upto 25% off",
        "image":"images/service-02.png",
    },
    {
        "title": "Support  24/7",
        "tagline": " Shop with en expert",
        "image":"images/service-03.png",
    },
    {
        "title": "Affordable Prices",
        "tagline": "Get Factory Default Price",
        "image":"images/service-04.png",
    },
    {
        "title": "Secure Payments",
        "tagline": "100% protected Payments",
        "image":"images/service-05.png",
    },
        
];