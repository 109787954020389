import React from "react";
import { Link } from "react-router-dom";

export const BlogCard = (props) => {
  const{ id, title,description, date, image } = props;
    return (
    
    <div  className="blog-card position-relative">
      <div className="card-image">
        <img 
        //src={item?.blogId?.images[0]?.url ? item?.blogId?.images[0]?.url : "images/blog-1.jpg"} 
        src={image ? image : "images/blog-1.jpg"} 
        className="img-fluid w-100" alt="blog" 
        />
        </div> 
      <div className="blog-content">
        <p className="date">{date}</p>
        <h5 className="title">{title}</h5>
        <p className="desc" dangerouslySetInnerHTML={{ __html: description?.substr(0,70)+"...",
      }}
      ></p>
        <Link to={"/blog/"+id} className="button">
          Read More
        </Link>
      </div>
    </div>
  );
  
};


export default BlogCard;
