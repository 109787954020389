/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import BlogCard from "../components/BlogCard";
//import ProductCard from "../components/ProductCard";
import SpecialProduct from "../components/SpecialProduct";
import Container from "../components/Container";
import { services } from "../utils/Data";
import { useDispatch,useSelector } from "react-redux";
import { getAllBlogs } from "../features/blogs/blogSlice";
import moment from "moment";
import { addToWishlist, getAllProducts } from "../features/products/productSlice";
//import addcart from "../images/add-cart.svg";
import view from "../images/view.svg";
//import prodcompare from "../images/prodcompare.svg";
import wish from "../images/wish.svg";
import videoBg from "../assets/at.mp4"
import videoBg2 from "../assets/pooja.mp4"
import videoBg3 from "../assets/virat.mp4"
import videoBg4 from "../assets/backpack2.mp4"


const Home = () => {
  const blogState = useSelector((state)=>state?.blog?.blog);
  const productState = useSelector((state)=>state?.product?.product);
  //console.log(productState);
  const navigate=useNavigate();
  const dispatch = useDispatch();
  //let location = useLocation();
  const addToWish = (id) => {
    //alert(id);
    dispatch(addToWishlist(id));
  };
	
  useEffect(() => {
    getblogs();
  //  getProducts();
    getallProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getblogs=()=>{
    dispatch(getAllBlogs());
  }; 
/*const getProducts=()=>{
  dispatch(getProducts());
}*/
  const getallProducts=() => {
    dispatch(getAllProducts());
  };

  return (    
    <>
    
      <Container class1="home-wrapper-1 py-3">
        <div className="row">
          <div className="col-6">
            <div className="main-banner position-relative ">
              <img
                src="images/banner6.jpg"
                className="img-fluid rounded-3"
                alt="main banner" />
  
              {/*<div className="main-banner-content position-absolute">
                <h4>SUPERCHARGED FOR PROS.</h4>
                <h5>iPad S13+ Pro.</h5>
                <p>From $999.00 or $41.62/mo.</p>
                <Link className="button">BUY NOW</Link>
  </div>*/}
            </div>
            
          </div>
          <div className="col-6">
            <div className="d-flex flex-wrap gap-10 justify-content-between align-items-center">
              <div className="small-banner position-relative">
                <img
                  src="images/banner12.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
               {/* <div className="small-banner-content position-absolute">
                  <h4>Best Sake</h4>
                  <h5>iPad S13+ Pro.</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>*/}
              </div>
              <div className="small-banner position-relative">
                <img
                  src="images/banner9.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                {/*<div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>*/}
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/main3.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
               {/* <div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>*/}
              </div>
              <div className="small-banner position-relative ">
                <img
                  src="images/banner13.jpg"
                  className="img-fluid rounded-3"
                  alt="main banner"
                />
                {/*<div className="small-banner-content position-absolute">
                  <h4>NEW ARRIVAL</h4>
                  <h5>But IPad Air</h5>
                  <p>
                    From $999.00 <br /> or $41.62/mo.
                  </p>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-3">
            <div className="famous-card position-relative">
            <video src={videoBg} autoPlay loop muted />
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
            <video src={videoBg2} autoPlay loop muted />
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
            <video src={videoBg3} autoPlay loop muted />
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
            <video src={videoBg4} autoPlay loop muted />
            </div>
          </div>
        </div>
      </Container>
      <Container class1="home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="servies d-flex align-items-center justify-content-between">
              {services?.map((i, j) => {
                return (
                  <div className="d-flex align-items-center gap-15" key={j}>
                    <img src={i.image} alt="services" />
                    <div>
                      <h6>{i.title}</h6>
                      <p className="mb-0">{i.tagline}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>      
      <Container class1="featured-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Featured Collection</h3>
          </div>
          <div className="row">
          {productState && productState?.map((item, index) => {
          if(item?.tags === "featured"){
              return (
                  <div key={index} className={"col-3"}>
                    <div
              className="product-card position-relative" 
            >
              <div className="wishlist-icon position-absolute">
                <button className="border-0 bg-transparent" onClick={()=> addToWish(item?._id)}>               
                  <img src={wish} alt="wishlist" />
                
                </button>
              </div>
              <div className="product-image">
                <img //src={watch} 
                  src={item?.images[0]?.url}
                  className="img-fluid mx-auto" 
                  alt="product" 
                  width={160} 
                ></img> 
                <img 
                //src={item?.images[0].url?item?.images[0].url:{watch2}}
                src={item?.images[1]?.url}
                className="img-fluid mx-auto" alt="product" width={160} />
              </div> 
              <div className="product-details">
                <h6 className="brand">{item?.brand}</h6>
                <h5 className="product-title">{item?.title}</h5>
                <ReactStars
                  count={5}
                  size={24}
                  value={item?.totalratings}
                  edit={false}
                  activeColor="#ffd700"
                />              
                <p className="price">$ {item?.price}</p>
              </div>
              <div className="action-bar position-absolute">
                <div className="d-flex flex-column gap-15">
                  {/*<button className="border-0 bg-transparent">
                    <img src={prodcompare} alt="compare" />
                  </button>*/}
                  <button className="border-0 bg-transparent">
                    <img onClick={() => navigate("/product/"+item?._id)} src={view} alt="view" />
                  </button>
                  {/*<button className="border-0 bg-transparent">
                    <img src={addcart} alt="addcart" />
                  </button>*/}
                </div>
              </div>
            </div>
          </div>
                );
            }
            })}</div>
        </div>
      </Container>
      <Container class1="famous-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-3">
            <div className="famous-card position-relative">
              <img
                src="images/banner7.jpg"
                className="img-fluid"
                alt="famous"
              />
          {/*    <div className="famous-content position-absolute">
                <h5>Big Screen</h5>
                <h6>Smart Watch Series 7</h6>
                <p>From $399or $16.62/mo. for 24 mo.*</p>
              </div>*/}
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
              <img
                src="images/banner8.jpg"
                className="img-fluid"
                alt="famous"
              />
              {/*<div className="famous-content position-absolute">
                <h5 className="text-dark">Studio Display</h5>
                <h6 className="text-dark">600 nits of brightness.</h6>
                <p className="text-dark">27-inch 5K Retina display</p>
              </div>*/}
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
              <img
                src="images/banner10.jpg"
                className="img-fluid"
                alt="famous"
              />
              {/*<div className="famous-content position-absolute">
                <h5 className="text-dark">smartphones</h5>
                <h6 className="text-dark">Smartphone 13 Pro.</h6>
                <p className="text-dark">
                  Now in Green. From $999.00 or $41.62/mo. for 24 mo. Footnote*
                </p>
              </div>*/}
            </div>
          </div>
          <div className="col-3">
            <div className="famous-card position-relative">
              <img
                src="images/banner11.jpg"
                className="img-fluid"
                alt="famous"
              />
              {/*<div className="famous-content position-absolute">
                <h5 className="text-dark">home speakers</h5>
                <h6 className="text-dark">Room-filling sound.</h6>
                <p className="text-dark">
                  From $699 or $116.58/mo. for 12 mo.*
                </p>
              </div>*/}
            </div>
          </div>
        </div>
      </Container>     
      <Container class1="premium-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Our Special Products</h3>
          </div>
          <div className="row">
          {productState && productState?.map((item, index) => {
          if(item?.tags === "special"){
              return (
                  <div key={index} className={"col-3"}>
                    <div
              className="product-card position-relative" 
            >
              <div className="wishlist-icon position-absolute">
                <button className="border-0 bg-transparent" onClick={()=> addToWish(item?._id)}>               
                  <img src={wish} alt="wishlist" />
                
                </button>
              </div>
              <div className="product-image">
                <img //src={watch} 
                  src={item?.images[0]?.url}
                  className="img-fluid mx-auto" 
                  alt="product" 
                  width={160} 
                ></img> 
                <img 
                //src={item?.images[0].url?item?.images[0].url:{watch2}}
                src={item?.images[1]?.url}
                className="img-fluid mx-auto" alt="product" width={160} />
              </div> 
              <div className="product-details">
                <h6 className="brand">{item?.brand}</h6>
                <h5 className="product-title">{item?.title}</h5>
                <ReactStars
                  count={5}
                  size={24}
                  value={item?.totalratings}
                  edit={false}
                  activeColor="#ffd700"
                />              
                <p className="price">$ {item?.price}</p>
              </div>
              <div className="action-bar position-absolute">
                <div className="d-flex flex-column gap-15">
                  {/*<button className="border-0 bg-transparent">
                    <img src={prodcompare} alt="compare" />
                  </button>*/}
                  <button className="border-0 bg-transparent">
                    <img onClick={() => navigate("/product/"+item?._id)} src={view} alt="view" />
                  </button>
                  {/*<button className="border-0 bg-transparent">
                    <img src={addcart} alt="addcart" />
                  </button>*/}
                </div>
              </div>
            </div>
          </div>
                );
            }
            })}</div>
        </div>
      </Container> 
      {/*<Container class1="special-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Special Products</h3>
          </div>
        </div>
        <div className="row">
        {productState && productState?.map((item, index) => {
          if(item?.tags==="special"){
            return( 
            <SpecialProduct key={index}               
              id={item?._id}
              brand ={item?.brand}
              title={item?.title}
              totalrating={item?.totalrating.toString()}
              price = {item?.price}
              sold={item?.sold}
              quantity={item?.quantity}
              />);
          }          
            })}         
        </div>
          </Container>*/}
      <Container class1="popular-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Our Popular Products</h3>
          </div>
        </div>
        <div className="row">
        {productState && productState?.map((item, index) => {
          if(item?.tags === "popular"){
              return (
                  <div key={index} className={"col-3"}>
                    <div className="product-card position-relative" >
              <div className="wishlist-icon position-absolute">
                <button className="border-0 bg-transparent" onClick={()=> addToWish(item?._id)}>               
                  <img src={wish} alt="wishlist" />
                
                </button>
              </div>
              <div className="product-image">
                <img //src={watch} 
                  src={item?.images[0]?.url}
                  className="img-fluid mx-auto" 
                  alt="product" 
                  width={160} 
                ></img> 
                <img 
                //src={item?.images[0].url?item?.images[0].url:{watch2}}
                src={item?.images[1]?.url}
                className="img-fluid mx-auto" alt="product" width={160} />
              </div> 
              <div className="product-details">
                <h6 className="brand">{item?.brand}</h6>
                <h5 className="product-title">{item?.title}</h5>
                <ReactStars
                  count={5}
                  size={24}
                  value={item?.totalratings}
                  edit={false}
                  activeColor="#ffd700"
                />
                <p className="price">$ {item?.price}</p>
              </div>
              <div className="action-bar position-absolute">
                <div className="d-flex flex-column gap-15">
                  {/*<button className="border-0 bg-transparent">
                    <img src={prodcompare} alt="compare" />
                  </button>*/}
                  <button className="border-0 bg-transparent">
                    {/*<img src={view} alt="view" />*/}
                     <img onClick={() => navigate("/product/"+item?._id)} src={view} alt="view" />
                  </button>
                  {/*<button className="border-0 bg-transparent">
                    <img src={addcart} alt="addcart" />
                  </button>*/}
                </div>
              </div>
            </div>
          </div>
                );
            }
            })}
          
        </div>
      </Container>
      <Container class1="marque-wrapper home-wrapper-2 py-5">
        <div className="row">
          <div className="col-12">
            <div className="marquee-inner-wrapper card-wrapper">
              <Marquee className="d-flex">
                <div className="mx-4 w-25">
                  <img src="images/at_logo.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/kamiliant.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/aristocrat.jpg" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/genie.jpg" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/fantasy.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/skybags.jpg" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/wildcraft.png" alt="brand" />
                </div>
                <div className="mx-4 w-25">
                  <img src="images/vip.png" alt="brand" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </Container>
      <Container class1="blog-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Our Latest Blogs</h3>
          </div>
        </div>
        <div className="row">
        {blogState && blogState?.map((item, index) => {
                  if(index<3){
                    return(
                      <div className="col-3" key={index} >
                        <BlogCard 
                        id={item?._id} 
                        title={item?.title} 
                        description={item?.description} 
                        image={item?.images[0]?.url}
                        date={moment(item?.createdAt).format(
                        "MMMM Do YYYY, h:mm a"
                        )}
                      />;
                      </div>
                    );
                  }
                })}
        </div>
      </Container>
    </>
  );
};

export default Home;
