import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Meta from "../components/Meta";
import Container from "../components/Container";

const RefundPloicy = () => {
  return (
    <>
      <Meta title={"Refund Policy"} />
      <BreadCrumb title="Refund Policy" />
      <Container class1="policy-wrapper py-5 home-wrapper-2">
        <div className="row">
          <div className="col-12">
            <div className="policy">
              <p>
              <h3>The Madura Bags Business Returns Policy</h3>

Most items purchased from sellers listed on The Madura Bags are returnable within the return window, except those that are explicitly identified as not returnable.
General Returns Policy
<ol>
<li>A product is returnable within the applicable return window if you’ve received it in a condition that is physically damaged, has missing parts or accessories, is defective, or different from its description on the product detail page on The Madura Bags.</li>
<li>If you report an issue with your Luggage Bag, Trolley, Duffle, Back pack, Hand bag. we may schedule a technician visit to your location. This visit is scheduled by placing an order with The Madura Bags through The Madura Bags Home Services. Subject to The Madura Bags Home Services Terms and Conditions, we will provide a resolution based on the technician's evaluation report.</li>
<li>We will process a return only if:
  <ul>
  <li>we determine that the product was not damaged while in your possession;</li>
<li>the product is not different from what we shipped to you;</li>
<li>you return the product in its original condition (with the brand’s/manufacturer's box, MRP tag intact, user manual, warranty card and all the accessories therein).</li></ul></li>
<li>Products may not be eligible for a return in some cases, including cases of buyer's remorse such as incorrect model or color of product ordered or incorrect product ordered.</li>
<li>You cannot return products marked as "non-returnable" on the product detail page.</li>
<li>You do not require additional information to return an eligible order, unless otherwise noted in the category-specific policy.</li>
</ol>
Note: If you've received a non-returnable product in a damaged/defective condition, you can contact us within 7 days from the delivery of the product.

              </p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default RefundPloicy;
